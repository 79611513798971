import { create, useModal } from "@ebay/nice-modal-react";
import { useState } from "react";

import { analyticsEvent } from "@up/analytics";
import { useTheme } from "@up/data";
import { Modal, ModalContent } from "@up/ui";

import { markAsDismissed, markAsSuccess } from "../helpers";
import { LeadCaptureUpcomingCoursev1Form } from "../LeadCaptureUpcomingCoursev1Form";

/**
 * You must define window.leadCaptureConfig.topics before using this component
 */
export const LeadCaptureUpcomingCoursev1Modal = create(
  ({ modalID, trigger = "unknown" }: { modalID: string; trigger?: string }) => {
    const theme = useTheme();
    const modal = useModal();
    const [isSuccess, setIsSuccess] = useState(false);

    const closeModal = () => {
      markAsDismissed(modalID);
      modal.hide();
      setTimeout(() => {
        modal.remove();
      }, 100);
    };

    return (
      <Modal close={closeModal} visible={modal.visible}>
        <ModalContent
          Heading={(headingProps) => {
            return (
              <h2
                {...headingProps}
                className={theme.heading2Class}
                data-testid="lead-capture-heading"
              >
                {isSuccess
                  ? null
                  : `Want to be notified when this course is available?`}
              </h2>
            );
          }}
        >
          <button
            className="absolute right-0 top-0 px-4 pb-1 pt-3 underline lg:pr-9 lg:pt-4"
            style={{ right: 0 }}
            onClick={closeModal}
          >
            close
          </button>

          <LeadCaptureUpcomingCoursev1Form
            close={closeModal}
            onSuccess={() => {
              setIsSuccess(true);
              analyticsEvent({
                event: "leadCaptureSuccess",
                formID: modalID,
                trigger,
              });
              markAsDismissed(modalID);
              markAsSuccess(modalID);
            }}
          />
        </ModalContent>
      </Modal>
    );
  },
);
