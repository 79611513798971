import { AnimatePresence, motion, useReducedMotion } from "framer-motion";
import ky from "ky";
import { useState } from "react";
import { useForm } from "react-hook-form";

import { analyticsEvent } from "@up/analytics";
import { useTheme } from "@up/data";
import { LoadingModal, Toast, UPLink } from "@up/ui";

import {
  Age,
  CanadianResident,
  CrossPartnerMarketingOptIn,
  Email,
  EuResident,
  FirstName,
  GuardianEmail,
  KaplanCanadaPartnerAccept,
  KaplanEUPartnerAccept,
  LastName,
  TOUPPAccept,
  Under16MarketingConsent,
} from "../form-fields";
import GenericError from "../GenericError";
import { LEAD_CAPTURE_UPCOMING_COURSE_V1_MODAL_ID } from "../modal-ids";

type SaveState = "idle" | "loading" | "error" | "success";
type FormBoolean = "true" | "false";

const BASE_URL = process.env["NEXT_PUBLIC_HALL_OF_RECORDS_URI"] as string;

const DEFAULT_VALUES: {
  email: string;
  formId: string;

  age?: string;
  canResident?: FormBoolean;
  canEmailConsent?: FormBoolean;
  euResident?: FormBoolean;
  euEmailConsent?: FormBoolean;
  underageConsent?: FormBoolean;
  crossPartnerMarketingOptIn?: FormBoolean;
  touppAccept?: "true";
  guardianEmail?: string;
} = {
  formId: LEAD_CAPTURE_UPCOMING_COURSE_V1_MODAL_ID,
  email: "",
};

export const LeadCaptureUpcomingCoursev1Form = function ({
  close,
  onSuccess,
  trigger = "unknown",
}: {
  close: () => void;
  onSuccess: () => void;
  trigger?: string;
}) {
  const theme = useTheme();
  const reducedMotion = useReducedMotion();
  const [saveStatus, setSaveStatus] = useState<SaveState>("idle");

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    watch,
  } = useForm({
    defaultValues: DEFAULT_VALUES,
    shouldUnregister: true,
  });

  const formValues = watch();

  const onSubmit = handleSubmit(async (data: typeof DEFAULT_VALUES) => {
    setSaveStatus("loading");

    if (!data) {
      return;
    }

    const element = document.getElementById("catalogueid");
    const id = element?.getAttribute("content") ?? null;

    const payload = {
      ...data,
      location: JSON.parse(JSON.stringify(window.location)),
      partnerId: theme.partnerID,
      trigger,
      catalogueId: id,
    };

    try {
      await ky
        .post(BASE_URL + `/v1/c/lc/${theme.partnerID}`, { json: payload })
        .json();
      setSaveStatus("success");
      onSuccess();
    } catch (err) {
      setSaveStatus("error");
    }
  });

  const IS_UNDER_16 = formValues.age
    ? formValues.age !== "" && parseInt(formValues.age) < 16
    : true;
  const IS_USA =
    formValues.canResident === "false" && formValues.euResident === "false";

  if (saveStatus === "success") {
    return (
      <div className="flex flex-col gap-4">
        <div className="pt-4">
          <Toast variant="success">
            Thank you for your interest. We will be in touch shortly.
          </Toast>
        </div>
        <UPLink
          className={theme.buttonPrimaryClass}
          href="/syllabus"
          onClick={() => {
            analyticsEvent({
              event: "downloadSleekNote",
            });
            close();
          }}
        >
          Close
        </UPLink>
      </div>
    );
  }

  return (
    <form onSubmit={onSubmit} className="flex flex-col gap-4">
      {saveStatus === "loading" ? <LoadingModal /> : null}

      <p className="mb-4">
        Share your information below so we can notify you when the application
        period is open.
      </p>

      <AnimatePresence>
        <motion.div
          className="flex flex-col gap-4"
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{
            ease: [0.87, 0, 0.13, 1],
            duration: reducedMotion ? 0 : 0.75,
          }}
        >
          <hr />
          <div className="grid grid-cols-1 gap-4 md:grid-cols-4">
            <div className="col-span-1">
              <div className="max-w-[10rem]">
                <Age
                  control={control}
                  formErrors={errors}
                  formValues={formValues}
                  register={register}
                />
              </div>
            </div>

            <div className="col-span-3">
              <Email
                formErrors={errors}
                formValues={formValues}
                register={register}
              />
            </div>
          </div>

          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <FirstName
              formErrors={errors}
              formValues={formValues}
              register={register}
            />

            <LastName
              formErrors={errors}
              formValues={formValues}
              register={register}
            />
          </div>

          <AnimatePresence initial={false}>
            <motion.div
              className="flex flex-col gap-4"
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{
                ease: [0.87, 0, 0.13, 1],
                duration: reducedMotion ? 0 : 0.75,
              }}
            >
              <GuardianEmail
                control={control}
                formErrors={errors}
                formValues={formValues}
                register={register}
              />
            </motion.div>
          </AnimatePresence>

          <hr />

          <AnimatePresence initial={false}>
            <div key="canada-resident">
              <CanadianResident
                formErrors={errors}
                formValues={formValues}
                register={register}
              />
            </div>
            {formValues.canResident === "true" ? (
              <motion.div
                key="canada-accept"
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{
                  ease: [0.87, 0, 0.13, 1],
                  duration: reducedMotion ? 0 : 0.75,
                }}
              >
                <KaplanCanadaPartnerAccept
                  formErrors={errors}
                  partnerName={theme.partnerName}
                  register={register}
                />
              </motion.div>
            ) : null}

            <hr />

            <div key="eu-resident">
              <EuResident
                formErrors={errors}
                formValues={formValues}
                register={register}
              />
            </div>

            {formValues.euResident === "true" ? (
              <motion.div
                key="eu-accept"
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{
                  ease: [0.87, 0, 0.13, 1],
                  duration: reducedMotion ? 0 : 0.75,
                }}
              >
                <KaplanEUPartnerAccept
                  formErrors={errors}
                  partnerName={theme.partnerName}
                  register={register}
                />
              </motion.div>
            ) : null}
          </AnimatePresence>

          <hr />

          <TOUPPAccept
            formErrors={errors}
            formValues={formValues}
            partnerName={theme.partnerOnlineName}
            register={register}
          />

          {IS_USA ? (
            IS_UNDER_16 ? (
              <Under16MarketingConsent register={register} />
            ) : (
              <p className="leading-snug text-sm opacity-60">
                By proceeding, I authorize Kaplan, Inc. as {theme.partnerName}
                &apos;s technology partner to collect, store, use, transfer,
                share, disclose or process in the United States my personal data
                including, sensitive personal information if volunteered by me.
              </p>
            )
          ) : null}

          <hr />

          <CrossPartnerMarketingOptIn register={register} />

          {saveStatus === "error" ? <GenericError /> : null}

          <div aria-hidden="true" />

          <p className="text-sm">
            By proceeding, I authorize Kaplan, Inc. as {theme.partnerName}
            &apos;s technology partner to collect, store, use, transfer, share,
            disclose or process in the United States my personal data including,
            sensitive personal information if volunteered by me.
          </p>

          <button
            className={`${theme.buttonPrimaryClass} w-full ${
              saveStatus === "loading" ? "cursor-wait opacity-50" : ""
            }`}
            disabled={saveStatus === "loading"}
            type="submit"
          >
            Submit
          </button>
        </motion.div>
      </AnimatePresence>
    </form>
  );
};
