import "../styles/index.scss";

import { register } from "@ebay/nice-modal-react";
import { getUID, themeStore } from "@up/data";
import { Layout } from "@up/layout";
import {
  LEAD_CAPTURE_TOPICS_V1_MODAL_ID,
  LEAD_CAPTURE_UPCOMING_COURSE_V1_MODAL_ID,
  LeadCaptureTopicsv1Modal,
  LeadCaptureUpcomingCoursev1Modal,
  useModalTimer,
} from "@up/lead-capture";
import { CustomNextApp } from "@up/next-customization";

import Footer from "~/components/Footer";
import { DEFAULT_SEO } from "~/lib/seo";
import { LIBRARY_THEME } from "~/lib/theme";

import type { AppProps } from "next/app";

themeStore.set(LIBRARY_THEME);

register(LEAD_CAPTURE_TOPICS_V1_MODAL_ID, LeadCaptureTopicsv1Modal);
register(
  LEAD_CAPTURE_UPCOMING_COURSE_V1_MODAL_ID,
  LeadCaptureUpcomingCoursev1Modal,
);
if (typeof window !== "undefined") {
  window.leadCaptureConfig = {
    topics: [
      "Business",
      "Communications",
      "Law and Government",
      "Medicine",
      "STEM",
      "College Credit",
    ],
  };
}

export default function CustomApp(customAppProps: AppProps) {
  const data = customAppProps.pageProps.page?.data;
  const attentionBanner = data
    ? {
        classes: `py-3 theme-white ${
          data.attention_banner_classes || data.attention_banner_css_classes
        }`,
        text: data.attention_banner_text || data.attention_banner,
      }
    : null;
  const uid = data?.uid || getUID(customAppProps.router);
  const navigationLinks = customAppProps.pageProps.navigation?.data?.links;
  const preview = Boolean(data?.preview);

  useModalTimer({
    modalID: LEAD_CAPTURE_TOPICS_V1_MODAL_ID,
  });

  register(LEAD_CAPTURE_TOPICS_V1_MODAL_ID, LeadCaptureTopicsv1Modal);

  if (typeof window !== "undefined") {
    window.leadCaptureConfig = {
      topics: [
        "Business",
        "Communications",
        "Law and Government",
        "Medicine",
        "STEM",
        "College Credit",
      ],
    };
  }

  const layoutProps = {
    attentionBanner,
    Footer,
    navigation: {
      links: navigationLinks,
      Logo: <img src="/logo-navigation.png" alt="Georgetown University Logo" />,
    },
    preview,
    uid,
  };

  return (
    <>
      {CustomNextApp({
        ...customAppProps,
        defaultSEO: DEFAULT_SEO,
        layoutProps,
        Layout,
      })}
    </>
  );
}
